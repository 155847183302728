<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="m-3">
                <div class="d-flex flex-column">


                    <b-col class="mb-3 p-3">
                        <h3>{{ $t('students_acknowledgement') }}</h3>

                        <span v-html="$t('students_acknowledgement_text')"></span>
                    </b-col>

                    <b-col class="mb-3 p-3">
                        <h3>{{ $t('contract_acceptance') }}</h3>

                        <span v-html="$t('contract_acceptance_text',{
                        name:studentData.name,
                        surname:studentData.surname,
                        date:formattedDate
                    })"></span>
                    </b-col>
                </div>
                <div class="col-12 d-flex justify-content-between align-items-center mt-3">
                    <ValidationProvider name="status" rules="required"
                                        v-slot="{valid, errors}">
                        <div class="mr-3">
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="form.status"
                                name="checkbox-1"
                                value="accepted"
                                unchecked-value="not_accepted"
                            >
                                {{ $t('I have read and confirm') }}
                            </b-form-checkbox>
                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <b-button type="submit" @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>

                </div>
            </b-row>
        </ValidationObserver>

    </div>
</template>
<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    data() {
        return {
            form: {},
            date: new Date()
        }
    },

    watch: {},

    computed: {
        ...mapGetters({
            studentData: "enrollmentAgreementForm/formData"
        }),
        formattedDate() {
            const formattedDate=moment(this.date).format('MM/DD/YYYY')
             return formattedDate

        }

    },

    methods: {
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
        back() {
            this.$emit('back', -1);
        }
    }
}
</script>
