<template>
    <div>
        <b-row class="mt-3">
            <b-col cols="12" class="d-flex justify-content-center align-item-center mb-5">
                <div class="logo-wrapper ">
                    <img src="../../../assets/img/BAU_Logo.png" height="130"/>
                </div>
            </b-col>
            <b-col class="d-flex justify-content-center align-item-center mb-5">
                <h1 class="text-center">{{ $t('bahcesehir_university') }}</h1>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-center align-item-center">
            </b-col>
            <b-col>
                <template>
                    <page1 v-if="currentPage==1"  @next="next($event)" @back="next($event)"/>
                </template>
                <template>
                    <page2 v-if="currentPage==2" @next="next($event)"  @back="next($event)"/>
                </template>
                <template>
                    <page3 v-if="currentPage==3" @next="next($event)"  @back="next($event)"/>
                </template>
                <template>
                    <page4 v-if="currentPage==4" @next="next($event)"  @back="next($event)"/>
                </template>
                <template>
                    <page5 v-if="currentPage==5" @next="next($event)"  @back="next($event)"/>
                </template>
                <template>
                    <page6 v-if="currentPage==6" @next="next($event)"  @back="next($event)"/>
                </template>
                <template>
                    <page7 v-if="currentPage==7" @next="next($event)"  @back="next($event)"/>
                </template>
                <template>
                    <page8 v-if="currentPage==8" @next="next($event)"  @back="next($event)"/>
                </template>
                <template>
                    <page9 v-if="currentPage==9" @next="next($event)"  @back="next($event)"/>
                </template>
                <template>
                    <page10 v-if="currentPage==10" @next="next($event)"  @back="next($event)"/>
                </template>


            </b-col>
        </b-row>
        <CommonModal size="lg" ref="sendForm">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('Enrollment Agreement Form') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <sendForm v-if="formName=='send-form'" @sendSuccessForm="successForm"></sendForm>
                </div>
            </template>
        </CommonModal>

    </div>
</template>
<script>

import Page1 from "@/modules/enrollmentForm/pages/page1.vue";
import Page2 from "@/modules/enrollmentForm/pages/page2.vue";
import Page3 from "@/modules/enrollmentForm/pages/page3.vue";
import Page4 from "@/modules/enrollmentForm/pages/page4.vue";
import Page5 from "@/modules/enrollmentForm/pages/page5.vue";
import Page6 from "@/modules/enrollmentForm/pages/page6.vue";
import Page7 from "@/modules/enrollmentForm/pages/page7.vue";
import Page8 from "@/modules/enrollmentForm/pages/page8.vue";
import Page9 from "@/modules/enrollmentForm/pages/page9.vue";
import Page10 from "@/modules/enrollmentForm/pages/page10.vue";
import sendForm from "@/modules/enrollmentForm/pages/sendForm.vue";

export default {
    components: {Page2, Page1, Page3, Page4, Page5, Page6, Page7, Page8, Page9, Page10,sendForm},
    data() {
        return {
            rows: 10,  // Toplam satır sayısı
            perPage: 1,// Sayfa başına satır sayısı
            currentPage: 1,
            masks: {
                input: "DD-MM-YYYY",
            },
            users: null,
            formName:null,
            showModal:false
        }
    },
    metaInfo() {
        return {
            title: this.$t("enrollment_agrement_form"),
        };
    },
    watch:{
      showModal(value){
          if (value){
              this.openHtmlModal()
          }
      },
      // currentPage(){
      //     this.openHtmlModal()
      // }
    },

    methods: {
        next(event) {
            if (this.currentPage < 11)
                if (this.currentPage <10){
                    this.currentPage += event;
                }else{
                    this.showModal=true
                }

        },
        openHtmlModal(){
            this.formName='send-form'
            this.$refs.sendForm.$refs.commonModal.show();
        },
        successForm(){
            this.formName=null
            this.$refs.sendForm.$refs.commonModal.hide();
        }
    }
}
</script>
<style>
.b-pagination .page-item:first-child,
.b-pagination .page-item:last-child {
    display: none;
}


</style>
