<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="m-3 ">
                <b-col>
                    <div class="mb-3">
                        <h3>{{ $t('tuition_and_fees') }}</h3>
                    </div>
                    <b-list-group class="mb-3">
                        <b-list-group-item class="d-flex justify-content-between align-items-center"
                                           v-for="(menu,i) in data" :key="i">
                            <span class="mr-5 font-weight-bold">{{ menu.key }}:</span>
                            <span><span v-if="menu.value">$</span>{{ menu.value }}</span>
                        </b-list-group-item>
                    </b-list-group>
                    <span class="mt-3"
                          v-html="$t('tuition_and_fees_text')">
                    </span>
                </b-col>
                <div class="col-12 d-flex justify-content-between align-items-center mt-3">
                    <ValidationProvider name="status" rules="required"
                                        v-slot="{valid, errors}">
                        <div class="mr-3">
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="form.status"
                                name="checkbox-1"
                                value="accepted"
                                unchecked-value="not_accepted"
                            >
                                I confirm that the information given in this form is true, complete and accurate
                            </b-form-checkbox>
                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <b-button type="submit" @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </b-row>
        </ValidationObserver>


    </div>
</template>
<script>
import EnrollmentAgreementForm from "@/services/EnrollmentAgreementForm";

export default {
    data() {
        return {
            form: {},
            masks: {
                input: "DD-MM-YYYY",
            },
            data: [
                {
                    key: 'Student Activities and Services Fee',
                    value: '135',
                },
                {
                    key: 'Technology Fee',
                    value: '165',
                },
                // {
                //     key: ' Student ID card',
                //     value: '20',
                // },
                {
                    key: ' Mandatory One-Time Fee Student ID card',
                    value: '20',
                },
                {
                    key: ' Late Registration Fee (per credit)',
                    value: '75',
                },
                {
                    key: ' Late Payment Fee (for each late / per credit)',
                    value: '25',
                },
                {
                    key: 'BAU English Proficiency Test',
                    value: '45',
                },
                {
                    key: 'Replacement Student ID Card',
                    value: '20',
                },
                {
                    key: 'Transcript Processing Fee (per transcript)',
                    value: '20',
                },
                {
                    key: ' Returned Check Fee',
                    value: '35',
                },
                {
                    key: ' Cancellation Fee*',
                    value: '100',
                },
                {
                    key: 'International Postage of Documents',
                    value: '130',
                }, {
                    key: 'Administrative Services Fee**',
                    value: '2000',
                },
                {
                    key: ' Student Housing Security Deposit',
                    value: '350',
                },
                {
                    key: 'Graduation Fee',
                    value: '200',
                },
                {
                    key: 'Diploma Processing Fee',
                    value: '150',
                },
                {
                    key: 'Diploma Replacement Fee',
                    value: '150',
                },

            ]
        }
    },

    created() {
        this.getData()
    },

    methods: {
        async getData() {
            let user = await JSON.parse(localStorage.getItem('user'));
            const response = await EnrollmentAgreementForm.getStudentInformation(user.id)
            let data = response.data.data
            this.data.push(
                {
                    key: 'Tuition per credit upon initial enrollment',
                    value: data.tuition_per_credit?data.tuition_per_credit:null,
                },
            )
        },
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
        back(){
            this.$emit('back', -1);
        }
    }
}
</script>
