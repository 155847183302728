import Api from '@/services/Index';

const getStudentInformation = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/programs/'+id);
}

const sendForm = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/enrollment-agreement',formData);
}



export default {
    getStudentInformation,
    sendForm
}
