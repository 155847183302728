<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="m-3">
                <b-col class="mb-3 p-3">
                    <h3>{{ $t('cancellation_refund_policy') }}</h3>

                    <div style="margin-bottom: 15px">
                        <div>
                            If a student elects to withdraw from specific course(s) or completely from the university, the
                            following refund schedule will be used to determine any outstanding financial obligation for which
                            the student may be responsible. Refunds exclude all mandatory fees and deposits, which are nonrefundable
                        </div>
                        <table>
                            <tr>
                                <th>Time of written notice of withdrawal</th>
                                <th>Tuition refund*</th>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td><span style="color: red">Up until three business days prior to 1st day of the semester</span></td>
                                <td>100% of tuition
                                    paid
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="width: 75%">From within 3 business days of the start of the semester through 25%
                                    of the semester
                                </td>
                                <td>50% of tuition
                                    paid
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td>From 25% through 50% of the semester</td>
                                <td>25% of tuition</td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td>After 50% of the semester</td>
                                <td>No refund</td>
                            </tr>
                        </table>
                        <div class="mt-3">
                             A student applicant will be considered a student as of the first day of classes. Request for withdrawal must be initiated on <span style="color: red">MyBAU</span> or submitted directly to the registrar to officially withdraw from BAU.<br> No refund will be honored without the withdrawal request initiated on <span style="color: red">MyBAU</span>or submitted to the registrar.<br> <span style="font-weight: bold">The official withdrawal date, for the purpose of a refund calculation, will be taken from the date the online request is received on <span style="color: red">MyBAU</span>  or by the registrar.</span><br><br>

                             BAU will issue refunds to individuals who have terminated their status as students within 45 days after receipt of the online withdrawal request. <br>If no payment was made, the University will bill the student for the due amount according to the schedule above.<br> If a student is participating in a payment plan and the installment payment is insufficient to cover the student's obligation according to the schedule above, then the university will send the student a bill for the difference.
                        </div>


                    </div>
                </b-col>
                <div class="col-12 d-flex justify-content-between align-items-center mt-3">
                    <ValidationProvider name="status" rules="required"
                                        v-slot="{valid, errors}">
                        <div class="mr-3">
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="form.status"
                                name="checkbox-1"
                                value="accepted"
                                unchecked-value="not_accepted"
                            >
                                {{ $t('scholarship_tuition_assistance_checkbox') }}
                            </b-form-checkbox>
                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <b-button type="submit" @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </b-row>
        </ValidationObserver>


    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
        }
    },

    watch: {},

    computed: {},

    methods: {
        async next(){
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid){
                this.$emit('next',1)
            }
        },
    }
}
</script>
