<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="m-3">
                <b-col class="mb-3 p-3">
                    <h3>{{ $t('financial_penalties_part2') }}</h3>

                    <span v-html="$t('financial_penalties_part2_text')"></span>
                </b-col>
                <div class="col-12 d-flex justify-content-between align-items-center mt-3">
                    <ValidationProvider name="status" rules="required"
                                        v-slot="{valid, errors}">
                        <div class="mr-3">
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="form.status"
                                name="checkbox-1"
                                value="accepted"
                                unchecked-value="not_accepted"
                            >
                                {{ $t('financial_penalties_part2_checkbox') }}
                            </b-form-checkbox>
                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <b-button type="submit" @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </b-row>
        </ValidationObserver>


    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
        }
    },

    watch: {},

    computed: {},

    methods: {
        async next(){
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid){
                this.$emit('next',1)
            }

        },
        back(){
            this.$emit('back', -1);
        }
    }
}
</script>
