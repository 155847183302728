<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="m-3">
                <b-col class="mb-3 p-2">
                    <h3>{{ $t('scholarship_tuition_assistance') }}</h3>

                    <div>
                        Total of {{registrationForm.total_discount_per_credit}} USD for {{registrationForm.total_credit_required_for_graduation}} credits.<span style="color: red">The</span> Per credit amount (total scholarship/tuition assistance divided
                        by
                        total credits) is applied to the credits enrolled during each course registration period. Repeat
                        credits and additional credits are exempt of the use of such scholarship/tuition assistance credits.
                        Students who earn a scholarship of any category should meet the following criteria to maintain the
                        scholarship:<br><br>
                        1)	Continuously enroll in courses to meet graduation requirements (unless taking an approved Leave of Absence), and<br>
                        2) Maintain a minimum Cumulative Grade Point Average (CGPA) of 2.0 at Undergraduate and 3.0
                        at Graduate level for any semester, and<br>
                        3) Avoid any disciplinary action for any reason.<br><br>
                        Scholarships/tuition assistance are available using the university’s funds allocated for students who need additional financial aid.
                        That is why continuing one’s studies at BAU is important as another student might have been rejected at the time of one’s application.
                        Therefore, if a student who was awarded any scholarship/tuition assistance withdraw from BAU for any reason, they are required to pay the award amount back for all course credits
                        they have completed since the scholarship/tuition assistance was awarded to them. BAU is committed to re-use these funds for students who need financial aid.
                    </div>
                </b-col>
                <div class="col-12 d-flex justify-content-between align-items-center mt-3">
                    <ValidationProvider name="status" rules="required"
                                        v-slot="{valid, errors}">
                        <div class="mr-3">
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="form.status"
                                name="checkbox-1"
                                value="accepted"
                                unchecked-value="not_accepted"
                            >
                                {{ $t('scholarship_tuition_assistance_checkbox') }}
                            </b-form-checkbox>
                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <b-button type="submit" @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </b-row>
        </ValidationObserver>


    </div>
</template>
<script>
import EnrollmentAgreementForm from "@/services/EnrollmentAgreementForm";

export default {
    data() {
        return {
            form: {},
            registrationForm:{}
        }
    },

    watch: {},

    computed: {},

    methods: {
        async next(){
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid){
                this.$emit('next',1)
            }

        },
        async getData() {
            let user = await JSON.parse(localStorage.getItem('user'));
            const response = await EnrollmentAgreementForm.getStudentInformation(user.id)
            let data = response.data.data
            this.registrationForm = {
                ...this.studentData,
                registration_date: data.registration_date,
                program_name: data.program_name,
                registration_semester_academic_year: data.registration_semester_academic_year,
                anticipated_end_date: data.anticipated_end_date,
                registration_type_text: data.registration_type_text,
                total_credit_required_for_graduation: data.total_credit_required_for_graduation,
                tuition_per_credit: data.tuition_per_credit,
                total_discount_per_credit: data.discount_per_credit*data.total_credit_required_for_graduation
            }
        },
        back(){
            this.$emit('back', -1);
        }
    },
    created() {
        this.getData()
    },
}
</script>
